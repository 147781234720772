@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  /* -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
  .flex-column {
    @apply flex flex-col;
  }

  .row-flex {
    @apply flex flex-row;
  }

  .create-account-left-container {
    @apply pl-[70px] pt-[90px] space-y-10;
  }
}

/* page-Loader Styles Start*/
.loader {
  width: 15px;
  height: 15px;
  border: 2px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Loader Styles End*/

/* checkbox */

.checkbox-container {
  display: flex;
  text-align: baseline;
  position: relative;
  padding-left: 35px;
  text-align: baseline;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #ffff;
  border-radius: 6px;
  -webkit-box-shadow: 0px 0px 0px 1px #d0d5dd;
  box-shadow: 0px 0px 0px 1px #d0d5dd;
}

.checkbox-container input:checked~.checkmark {
  background-color: #8dc63f;
  border-radius: 7px;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: 700;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

::-webkit-scrollbar {
  width: 10px;
  height: 0px;
}

/* Set the background color of the scrollbar */
::-webkit-scrollbar-thumb {
  background-color: #aac485;
  border-radius: 12px;
}

/* Page Loader */
.page-loader {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 10px;
}

.page-loader div {
  width: 8%;
  height: 24%;
  background: rgb(128, 128, 128);
  position: absolute;
  left: 50%;
  top: 30%;
  opacity: 0;
  border-radius: 50px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  animation: fade458 1s linear infinite;
}

@keyframes fade458 {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.25;
  }
}

.page-loader .bar1 {
  transform: rotate(0deg) translate(0, -130%);
  animation-delay: 0s;
}

.page-loader .bar2 {
  transform: rotate(30deg) translate(0, -130%);
  animation-delay: -1.1s;
}

.page-loader .bar3 {
  transform: rotate(60deg) translate(0, -130%);
  animation-delay: -1s;
}

.page-loader .bar4 {
  transform: rotate(90deg) translate(0, -130%);
  animation-delay: -0.9s;
}

.page-loader .bar5 {
  transform: rotate(120deg) translate(0, -130%);
  animation-delay: -0.8s;
}

.page-loader .bar6 {
  transform: rotate(150deg) translate(0, -130%);
  animation-delay: -0.7s;
}

.page-loader .bar7 {
  transform: rotate(180deg) translate(0, -130%);
  animation-delay: -0.6s;
}

.page-loader .bar8 {
  transform: rotate(210deg) translate(0, -130%);
  animation-delay: -0.5s;
}

.page-loader .bar9 {
  transform: rotate(240deg) translate(0, -130%);
  animation-delay: -0.4s;
}

.page-loader .bar10 {
  transform: rotate(270deg) translate(0, -130%);
  animation-delay: -0.3s;
}

.page-loader .bar11 {
  transform: rotate(300deg) translate(0, -130%);
  animation-delay: -0.2s;
}

.page-loader .bar12 {
  transform: rotate(330deg) translate(0, -130%);
  animation-delay: -0.1s;
}

.grid-container {
  /**
   * User input values.
   */
  --grid-layout-gap: 10px;
  --grid-column-count: 3;
  --grid-item--min-width: 340px;

  @media only screen and (min-width: 1200px) {
    --grid-item--min-width: 300px;
  }

  @media only screen and (max-width: 600px) {
    --grid-item--min-width: 200px;
  }

  /**
    * Calculated values.
    */
  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

  display: grid;
  grid-template-columns: repeat(auto-fill,
    minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr));
  grid-gap: var(--grid-layout-gap);
}

.grid-container-gap-xl {
  --grid-layout-gap: 32px;
}

.invitations:hover {
  background-color: #048583;
}

.opportunities:hover {
  background-color: #50cd89;
}

.bid-in-review:hover {
  background-color: #415be3;
}

.schedule-site-visit:hover {
  background-color: #be5cfb;
}

.confirmed-site-visit:hover {
  background-color: #e79629;
}

textarea {
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  /*remove the resize handle on the bottom right*/
}

.chat-textBox {
  padding-top: 20px;
}

em-emoji-picker {
  position: fixed;
  z-index: 999999;
}

.chat-container-sm {
  height: calc(100vh - 285px);
  transition: all 0.2s ease;
  /* Adjust the transition duration as desired */
  scroll-behavior: smooth;
  overflow-y: scroll;

  @media only screen and (max-width: 600px) {
    height: calc(100vh - 500px);
  }
}

.chat-container-lg {
  height: calc(100vh - 355px);
  transition: all 0.1s ease;
  /* Adjust the transition duration as desired */
  scroll-behavior: smooth;
  overflow-y: scroll;
}

.chat-container::-webkit-scrollbar-thumb {
  background-color: #b7bdcb;
}

.chat-container::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

.chat-container> :first-child {
  margin-top: auto;
}

.empty-container {
  height: calc(100vh - 150px);
}


.ant-btn-default:not(:disabled):hover {
  color: #AAC485 !important;
  border-color: #AAC485 !important;
}


.react-international-phone-country-selector-button {
  height: 50px !important;
}